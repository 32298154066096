<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs5 md5>
        <v-autocomplete
          v-model="model.modeId"
          label="Mode"
          :counter="max"
          :items="gameType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
        <v-text-field v-model="model.order" label="Order" hide-details></v-text-field>
      </v-flex>
      <v-btn color="success" @click="save">Save</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import gameType from "@/lib/gameType";
import router from "@/router";
export default {
  data: () => ({
    model: {
      modeId:0,
      order:0
    },
    gameType: gameType,
    alert: false,
    max: 120,
    menu: false,
    modal: false,
    menu2: false,
  }),

  methods: {
    async save() {
        await this.$store.dispatch("nowTrends/post", this.model);
      router.push("/nowTrend");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
};
</script>
